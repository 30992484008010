<script setup>
import { useApp } from "~/composables/useApp";
import {
  chapterTitle,
  findBook,
  renderChapterHeadingHtml,
  renderVerseHtml,
} from "~/composables/useBible";
import { useBiblePlans, useBiblePlansProgress } from "~/composables/useBiblePlans";
import { compilePlace } from "~/composables/useBiblePlans";

const { settingsComputedClass } = useApp();
const { bibles, appVars } = useApp();
const { fetchBiblePlanPassage } = useBiblePlans();
const { biblePlansProgressColl } = useBiblePlansProgress();
const {
  addPlansProgress,
  hasDayInProgress,
  getLastReadPlanDay,
} = useBiblePlansProgress();

const props = defineProps(["modelValue", "planModel"]);
const emit = defineEmits(["update:modelValue"]);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

const selectedBible = ref(null);
const selectedDay = ref(1);
const selectedPlanPassage = ref([]);
const selectedPlanPassagePlain = computed(() =>
  selectedPlanPassage.value?.flatMap((el) => el[1])
);

const scrollableHtmlElem = ref(null);

const dayPlaces = computed(() => compilePlace(selectedPlanPassagePlain.value));
const dialogRef = ref(null);

const hasReadButtonEnabled = computed(() => {
  // first day of the plan is enabled by default
  if (selectedDay.value == 1) return true;

  // if prev day of the plan has been read
  return hasDayInProgress({
    planSlug: props.planModel.plan_slug,
    planDay: selectedDay.value - 1,
  });
});

const dayHasSelected = (day) => selectedDay.value == day;

async function handleOnOpen() {
  if (!props.planModel?.plan_slug) return;

  selectedBible.value =
    bibles.singleBible?.location?.bibleModel?.bibleCode || selectedBible.value;

  // setup active day
  selectedDay.value = getLastReadPlanDay({ planSlug: props.planModel.plan_slug }) + 1;

  // loadPlanPassages();
  scrollToUnredDay();
}

function handleOnSelectDay(day) {
  selectedDay.value = day;
  scrollToTop(scrollableHtmlElem.value);
}

function handleOnReadDone() {
  addPlansProgress({
    planSlug: props.planModel.plan_slug,
    planDay: selectedDay.value,
  });

  // close dialog after read
  dialog.value = false;
}

async function loadPlanPassages() {
  const passage = await fetchBiblePlanPassage({
    planBibleCode: selectedBible.value,
    planSlug: props.planModel?.plan_slug,
    planDay: selectedDay.value,
  });

  selectedPlanPassage.value = groupBy(passage, (p) => p.bookCode);
}

function scrollToUnredDay() {
  if (!dialogRef.value) return;

  const daysPanelElem = dialogRef.value.querySelector(".days");
  const daysNodesColl = dialogRef.value.querySelectorAll(".days .item");
  const daysColl = Array.from(daysNodesColl);
  const progressDays = biblePlansProgressColl.value.length;
  const lastDay = daysColl[progressDays];

  daysPanelElem.scrollTo({ left: lastDay.offsetLeft - 30 });
}

function watchProps() {
  watch(
    () => [selectedBible.value, selectedDay.value],
    () => {
      if (selectedBible.value && selectedDay.value) loadPlanPassages();
    }
  );
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <v-dialog
    v-model="dialog"
    class="devotion-dialog"
    :options="{
      height: 'var(--app-height)',
      width: '100vw',
      maxWidth: 'var(--screen-max-width)',
    }"
    @onBeforeOpen="handleOnOpen"
  >
    <v-navigation-header
      @goback="dialog = false"
      :label="transl(planModel?.plan_name)"
      class="px-3"
    ></v-navigation-header>

    <div class="dialogs-content" :ref="(el) => (dialogRef = el)">
      <div class="days mt-3">
        <div
          class="item"
          v-for="day in planModel?.plan_days"
          @click="handleOnSelectDay(day)"
          :class="{
            active: dayHasSelected(day),
            done: hasDayInProgress({
              planSlug: planModel.plan_slug,
              planDay: day,
            }),
          }"
        >
          <span>{{ day }}</span>
          <div class="label">{{ transl("Day") }}</div>
        </div>
      </div>

      <div class="plans-title pb-2">
        <div class="bible-select-row">
          <div>{{ dayPlaces }}</div>
          <VPlanBibleSelect
            class="ml-2 bible-select-el"
            :bibleCode="selectedBible"
            @select="(bibleCode) => (selectedBible = bibleCode)"
          />
        </div>
      </div>

      <div class="verses-el" :ref="(el) => (scrollableHtmlElem = el)">
        <div class="verses-list-el" :class="settingsComputedClass">
          <div v-for="[bookCode, planPlacesVerses] in selectedPlanPassage" class="mt-10">
            <p class="chapter-heading">
              {{ findBook({ bibleCode: selectedBible, bookCode })?.bookNameCanonLong }},
              {{ transl(chapterTitle(bookCode), planPlacesVerses?.[0]?.bibleLocale) }}
              {{ planPlacesVerses?.[0]?.chapterNum }}
            </p>

            <template v-for="(verseModel, index) in planPlacesVerses">
              <span
                v-html="renderVerseHtml(verseModel, null, { header: index != 0 })"
              ></span>
            </template>
          </div>
        </div>
        <div
          class="easy-button-filled mt-6"
          @click="handleOnReadDone"
          v-if="hasReadButtonEnabled"
        >
          {{ transl("Recited") }}
        </div>
        <div class="padding-bottom"></div>
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
.dialogs-content {
  overflow-y: auto;
  height: 100%;
  background-color: white;
  padding: 0 12px 0 20px;
  font-family: "Golos Text";
}

.dialogs-content.settings-size-lg {
  font-size: 24px !important;
  line-height: 35px;
}

.dialogs-content.settings-size-md {
  font-size: 20px !important;
  line-height: 32px;
}

.dialogs-content.settings-size-sm {
  font-size: 16px !important;
  line-height: 28px;
}

.plans-title {
  font-weight: 600;
}
.todays-text {
  font-weight: 400;
}
.padding-bottom {
  margin-bottom: 200px;
}

.dialogs-content :deep() p {
  margin-top: 10px;
  text-indent: 30px;
}
.days {
  display: flex;
  /* gap: 14px; */
  overflow-x: scroll;
  text-align: center;
  user-select: none;
  width: 100%;
  padding: 20px 0;
}
.days .label {
  font-size: 12px;
  padding-top: 9px;
}
.days .item {
  padding-right: 14px;
}
.days .item span {
  min-width: 35px;
  min-height: 35px;
  max-height: 35px;
  max-width: 35px;

  border-radius: 10px;
  padding: 4px 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  border: solid 1px #bbc2d4;

  box-shadow: none;
  color: #292937;
}
.days .item.done span {
  border: solid 1px #00bfa4;
  color: #00bfa4;
}
.days .item.active span {
  background: #00bfa4;
  border: solid 1px #00bfa4;
  box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
  color: #fff;
}
.days span:hover {
  opacity: 0.8;
}
.bible-select-el :deep(.select-button) {
  height: 40px;
  line-height: 40px;
}
.bible-select-el :deep(.select-button .more) {
  top: 14px;
}
.bible-select-row {
  gap: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  font-size: 20px;
}
.bible-select-el {
  margin-left: auto;
  max-height: 50px;
}
.v-menu-wrapper.ml-2.bible-select-el {
  width: 100%;
  max-width: 170px;
  margin-left: auto !important;
}
.easy-button-filled {
  background-color: #00bfa4;
  color: #fff;
  border: solid 1px #00bfa4;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 25px;
  display: inline-block;
  cursor: pointer;
}
.easy-button-filled:hover {
  opacity: 0.8;
}

.verses-el {
  height: calc(100vh - 230px);
  overflow-y: auto;

  overflow-y: auto;
  padding: 0 80px;
}
@media (max-width: 800px) {
  .verses-el {
    padding: 0 40px;
  }
}
@media (max-width: 600px) {
  .verses-el {
    padding: 0 10px;
  }
}
</style>
