
export function compilePlace(verses: any[]) {
    if (!verses) return "";
  
    const booksGroup = groupBy(verses, (el) => el.bookCode);
    const places: any = [];
  
    booksGroup.map(([k, groupValues]) => {
      const firstVerse = first(groupValues);
      const lastVerse = last(groupValues);
      const bookCode = firstVerse.bookNameCanonLong;
      const isSameChapter = firstVerse.chapterNum == lastVerse.chapterNum;
      const fromPlace = !isSameChapter
        ? `${firstVerse.chapterNum}:${firstVerse.verseNum}`
        : `${firstVerse.chapterNum}:${firstVerse.verseNum}-${lastVerse.verseNum}`;
  
      const toPlace = !isSameChapter
        ? `-${lastVerse.chapterNum}:${lastVerse.verseNum}`
        : "";
  
      places.push(`${bookCode} ${fromPlace}${toPlace}`);
    });
    return places.join("; ");
  }